import {
  ArrowBarLeft,
  ArrowBarRight,
  ArrowCounterclockwise,
  Basket,
  Building,
  Calendar2Check,
  Calendar2Month,
  Calendar2Range,
  CalendarWeek,
  Camera,
  CashStack,
  CheckCircleFill,
  CheckSquare,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  Coin,
  CurrencyExchange,
  Dash,
  DashCircleFill,
  Download,
  EmojiSunglasses,
  EnvelopeFill,
  EnvelopeOpen,
  ExclamationCircleFill,
  EyeFill,
  FileEarmarkEasel,
  FileEarmarkImage,
  FileEarmarkPlus,
  FileEarmarkSpreadsheet,
  FileEarmarkText,
  Files,
  FileSpreadsheet,
  FileText,
  FuelPump,
  GraphUp,
  Headphones,
  HouseDoor,
  Houses,
  InfoLg,
  Journals,
  Keyboard,
  KeyFill,
  ListCheck,
  ListStars,
  Lock,
  Newspaper,
  PencilSquare,
  People,
  PeopleFill,
  PersonBadge,
  PersonCheck,
  PersonFillX,
  PersonLinesFill,
  PersonPlus,
  PlusCircleFill,
  PlusLg,
  Prescription2,
  Printer,
  PrinterFill,
  QuestionCircleFill,
  QuestionLg,
  Send,
  Speaker,
  Toggles,
  Trash,
  UniversalAccess,
  Unlock,
  Upload,
  Wallet2,
  ZoomIn,
  ZoomOut,
} from "react-bootstrap-icons";

export const icons = {
  ArrowBarLeft,
  ArrowBarRight,
  ArrowCounterclockwise,
  Basket,
  Building,
  Calendar2Check,
  Calendar2Month,
  Calendar2Range,
  CalendarWeek,
  Camera,
  CashStack,
  CheckCircleFill,
  CheckSquare,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  Coin,
  CurrencyExchange,
  Dash,
  DashCircleFill,
  Download,
  EmojiSunglasses,
  EnvelopeFill,
  EnvelopeOpen,
  ExclamationCircleFill,
  EyeFill,
  FileEarmarkEasel,
  FileEarmarkImage,
  FileEarmarkPlus,
  FileEarmarkSpreadsheet,
  FileEarmarkText,
  FileSpreadsheet,
  Files,
  FileText,
  FuelPump,
  GraphUp,
  Headphones,
  HouseDoor,
  Houses,
  InfoLg,
  Journals,
  Keyboard,
  KeyFill,
  ListCheck,
  ListStars,
  Lock,
  Newspaper,
  PencilSquare,
  People,
  PeopleFill,
  PersonBadge,
  PersonCheck,
  PersonFillX,
  PersonLinesFill,
  PersonPlus,
  PlusCircleFill,
  PlusLg,
  Prescription2,
  Printer,
  PrinterFill,
  QuestionLg,
  QuestionCircleFill,
  Send,
  Speaker,
  Toggles,
  Trash,
  UniversalAccess,
  Unlock,
  Upload,
  Wallet2,
  ZoomIn,
  ZoomOut,
};
