export default class Staff {
    toJSON() {
        return Object.assign({}, this);
    }
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any*/
    constructor(object) {
        this.techniker = [];
        this.technikerV = [];
        this.merchandise = [];
        this.kasse = [];
        this.kasseV = [];
        this.mod = [];
        this.ersthelfer = [];
        this.technikerNotNeeded = true;
        this.technikerVNotNeeded = true;
        this.kasseNotNeeded = true;
        this.kasseVNotNeeded = true;
        this.modNotNeeded = true;
        this.merchandiseNotNeeded = true;
        this.ersthelferNotNeeded = false;
        if (object && Object.keys(object).length !== 0) {
            Object.assign(this, object, {
                techniker: object.techniker || [],
                technikerV: object.technikerV || [],
                kasse: object.kasse || [],
                kasseV: object.kasseV || [],
                merchandise: object.merchandise || [],
                mod: object.mod || [],
                ersthelfer: object.ersthelfer || [],
            });
        }
    }
    getStaffCollection(forType) {
        return this[forType];
    }
    get noStaffNeeded() {
        return this.technikerNotNeeded && this.technikerVNotNeeded && this.kasseNotNeeded && this.kasseVNotNeeded && this.merchandiseNotNeeded;
    }
    get tooltipInfos() {
        if (this.noStaffNeeded) {
            return "";
        }
        const kasseAlle = this.kasseV.concat(this.kasse);
        const kassiererText = kasseAlle.length > 0 ? kasseAlle.join(", ") : "-";
        const technikerAlle = this.technikerV.concat(this.techniker);
        const technikerText = technikerAlle.length > 0 ? technikerAlle.join(", ") : "-";
        return " Kasse: " + kassiererText + " | Techniker: " + technikerText;
    }
    get kasseFehlt() {
        return (!this.kasseNotNeeded && this.kasse.length === 0) || (!this.kasseVNotNeeded && this.kasseV.length === 0);
    }
    addUserToSection(user, section) {
        this.getStaffCollection(section).push(user.id);
    }
    removeUserFromSection(user, section) {
        const sec = this.getStaffCollection(section);
        const index = sec.indexOf(user.id);
        sec.splice(index, 1);
    }
    get allNames() {
        return this.kasseV
            .concat(this.kasse)
            .concat(this.technikerV)
            .concat(this.techniker)
            .concat(this.mod)
            .filter((s) => !!s);
    }
}
